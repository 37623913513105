import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { getShareDiamondsAction } from 'store/actions'
import { Searchbar, Diamond, Expired, Disclaimer, Footer } from 'components'
import { LeftArrowIcon, RightArrowIcon } from 'components/svg'

import classes from './styles.module.css'

const SharePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const id = params.id
  const pos = params.pos
  const [hasExpired, setHasExpired] = useState(false)
  const [diamonds, setDiamonds] = useState([])
  const [selectedId, setSelectedId] = useState(null)

  const getSelectedIndex = () => {
    return diamonds.findIndex((diamond) => diamond.current.id === selectedId)
  }

  const getDiamonds = (id) => {
    setHasExpired(false)
    setDiamonds([])

    dispatch(getShareDiamondsAction(id))
      .then((response) => {
        setDiamonds(response)

        if (pos) {
          const index = parseInt(pos) - 1

          if (Number.isInteger(index)) {
            const diamond = response[index] ? response[index] : response[0]

            setSelectedId(diamond.current.id)
          } else {
            navigate(`/share/${id}`)

            setSelectedId(response[0].current.id)
          }
        } else {
          setSelectedId(response[0].current.id)
        }

        if (!response) {
          setHasExpired(true)
        }
      })
      .catch(() => {
        setHasExpired(true)
      })
  }

  const handleNavigateClick = (direction) => {
    const index = getSelectedIndex() + direction
    let diamondId
    let nextIndex

    if (index > diamonds.length - 1) {
      diamondId = diamonds[0].current.id
      nextIndex = 0
    } else if (index < 0) {
      diamondId = diamonds[diamonds.length - 1].current.id
      nextIndex = diamonds.length - 1
    } else {
      diamondId = diamonds[index].current.id
      nextIndex = index
    }

    setSelectedId(diamondId)

    navigate(`/share/${id}/${nextIndex + 1}`)
  }

  useEffect(() => {
    getDiamonds(id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.sharePageContainer}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Searchbar />
        </div>
        {hasExpired ? (
          <div className={classes.noResults}>
            <Expired />
          </div>
        ) : null}
        {diamonds.length ? (
          <div className={classes.viewer}>
            <div className={classnames(classes.navigator, { [classes.visible]: diamonds.length > 1 })}>
              <div className={classes.arrow} onClick={() => handleNavigateClick(-1)}>
                <LeftArrowIcon />
              </div>
              <div className={classes.counter}>
                {getSelectedIndex() + 1}/{diamonds.length} DIAMONDS
              </div>
              <div className={classes.arrow} onClick={() => handleNavigateClick(1)}>
                <RightArrowIcon />
              </div>
            </div>
            <div className={classnames(classes.positioner, { [classes.withNav]: diamonds.length > 1 })}>
              <div className={classnames(classes.diamond, { [classes.withNav]: diamonds.length > 1 })}>
                <Diamond diamond={diamonds[getSelectedIndex()]} />
              </div>
            </div>
          </div>
        ) : null}

        <div className={classes.footer}>
          <div className={classes.disclaimer}>
            <Disclaimer />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default SharePage
