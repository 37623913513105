import React from 'react'

export function FlagIcon({ country = "", style }) {
  let flagPath;
  switch (country.toLowerCase()) {
    case "botswana":
      flagPath = "/images/flags/botswana.svg";
      break;
    case "canada":
      flagPath = "/images/flags/canada.svg";
      break;
    case "namibia":
      flagPath = "/images/flags/namibia.svg";
      break;
    case "south africa":
      flagPath = "/images/flags/south-africa.svg";
      break;
    case "dtc":
      flagPath = "/images/flags/DTC.svg";
  }

  if (!flagPath) return null;

  return (<img style={style} src={flagPath} />);
}
